import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'oee', pathMatch: 'full' },
  {
    path: 'inicio',
    loadChildren: () => import('./pages/inicio/inicio.module').then( m => m.InicioPageModule)
  },
  {
    path: 'datas',
    loadChildren: () => import('./pages/datas/datas.module').then( m => m.DatasPageModule)
  },
  {
    path: 'toast',
    loadChildren: () => import('./pages/toast/toast.module').then( m => m.ToastPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'relatorios',
    loadChildren: () => import('./pages/relatorios/relatorios.module').then( m => m.RelatoriosPageModule)
  },
  {
    path: 'configuracoes',
    loadChildren: () => import('./pages/configuracoes/configuracoes.module').then( m => m.ConfiguracoesPageModule)
  },
  {
    path: 'configuracoes-de-busca',
    loadChildren: () => import('./pages/configuracoes-de-busca/configuracoes-de-busca.module').then( m => m.ConfiguracoesDeBuscaPageModule)
  },
  {
    path: 'home/:usuario/:senha/:idDoDeposito',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'inicio/:idDoDeposito',
    loadChildren: () => import('./pages/inicio/inicio.module').then( m => m.InicioPageModule)
  },
  {
    path: 'relatorios/:idDoDeposito',
    loadChildren: () => import('./pages/relatorios/relatorios.module').then( m => m.RelatoriosPageModule)
  },
  {
    path: 'relatori-gas',
    loadChildren: () => import('./pages/relatori-gas/relatori-gas.module').then( m => m.RelatoriGasPageModule)
  },
  {
    path: 'relatorio-agua',
    loadChildren: () => import('./pages/relatorio-agua/relatorio-agua.module').then( m => m.RelatorioAguaPageModule)
  },
  {
    path: 'historico',
    loadChildren: () => import('./pages/historico/historico.module').then( m => m.HistoricoPageModule)
  },
  {
    path: 'impressao-nota',
    loadChildren: () => import('./pages/impressao-nota/impressao-nota.module').then( m => m.ImpressaoNotaPageModule)
  },
  {
    path: 'impressao-nota/:id/:data_da_ocorrencia/:horario/:turno/:linha/:produto/:destino/:quantidade_retida/:descricao/:causa/:acao_corretiva/:disposicao/:responsavel/:revisada/:liberada/:perda',
    loadChildren: () => import('./pages/impressao-nota/impressao-nota.module').then( m => m.ImpressaoNotaPageModule)
  },
  {
    path: 'impressao-nota/:id/:produto/:quantidade/:valor_unti/:total/:forma_de_pagamento/:debito_ou_credito/:troco/:cpf_cnpj/:endereco/:cidade/:numero/:complemento/:ponto_de_referencia/:data/:hora/:telefone/:cep/:destino_da_impressao',
    loadChildren: () => import('./pages/impressao-nota/impressao-nota.module').then( m => m.ImpressaoNotaPageModule)
  },
  {
    path: 'gerenciamento-de-usuario',
    loadChildren: () => import('./pages/gerenciamento-de-usuario/gerenciamento-de-usuario.module').then( m => m.GerenciamentoDeUsuarioPageModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then( m => m.PagesPageModule)
  },
  {
    path: 'editar-ocorrencias',
    loadChildren: () => import('./pages/editar-ocorrencias/editar-ocorrencias.module').then( m => m.EditarOcorrenciasPageModule)
  },
  {
    path: 'detalhes',
    loadChildren: () => import('./pages/detalhes/detalhes.module').then( m => m.DetalhesPageModule)
  },
  {
    path: 'ocorrencias',
    loadChildren: () => import('./pages/ocorrencias/ocorrencias.module').then( m => m.OcorrenciasPageModule)
  }, 
  {
    path: 'editar-ocorrencias/:id/:data_da_ocorrencia/:horario/:turno/:linha/:produto/:quantidade_retida/:destino/:descricao/:causa/:acao_corretiva/:disposicao/:responsavel/:revisada/:liberada/:perda/:observacoes',
    loadChildren: () => import('./pages/editar-ocorrencias/editar-ocorrencias.module').then( m => m.EditarOcorrenciasPageModule)
  },
  {
    path: 'detalhes',
    loadChildren: () => import('./pages/detalhes/detalhes.module').then( m => m.DetalhesPageModule)
  },
  {
    path: 'detalhes/:id/:data_da_ocorrencia/:horario/:turno/:linha/:produto/:quantidade_retida/:destino/:descricao/:causa/:acao_corretiva/:disposicao/:responsavel/:revisada/:liberada/:perda/:observacoes',
    loadChildren: () => import('./pages/detalhes/detalhes.module').then( m => m.DetalhesPageModule)
  },
  {
    path: 'home-relatorio',
    loadChildren: () => import('./pages/home-relatorio/home-relatorio.module').then( m => m.HomeRelatorioPageModule)
  },
  {
    path: 'relatoris-desvios-matriz',
    loadChildren: () => import('./pages/relatoris-desvios-matriz/relatoris-desvios-matriz.module').then( m => m.RelatorisDesviosMatrizPageModule)
  },
  {
    path: 'oee',
    loadChildren: () => import('./pages/oee/oee.module').then( m => m.OeePageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
